import React from 'react';
import { StaticQuery, graphql, Link } from "gatsby"
//import b1 from "../../assets/images/b1.png";
//import b2 from "../../assets/images/b2.png";
//import b3 from "../../assets/images/b3.png";

const MostReadArticle = () =>{
    return(
     <StaticQuery
      query={graphql`
         query {
            allWpPost {
               edges {
                 node {
                   content
                   TeamlanceSingleBlogData {
                     customData {
                       customContent
                       customTitle
                     }
                   }
                   slug
                   status
                   title
                   date(formatString: "YYYY-MM-DD")
                   featuredImage {
                     node {
                       sourceUrl
                     }
                   }
                   categories {
                     nodes {
                       name
                       slug
                     }
                   }
                 }
               }
             }
         }
      `}
      render={data => {
         if(data && 
            data.allWpPost &&
            data.allWpPost.edges
             ){
               var mostRecentDate = new Date(Math.max.apply(null, data.allWpPost.edges.map( e => {
                  return new Date(e.node.date);
               })));
               var mostRecentObject = data.allWpPost.edges.filter( e => { 
                   var d = new Date( e.node.date ); 
                   return d.getTime() == mostRecentDate.getTime();
               });
            }
        
           return(
             <div className="container most-read">
               <div className="row d-flex justify-content-between mb-3 align-items-center">
                  <h1 className="related-title mt-5">Most Recent Articles</h1>
               </div>
               <div className="row">
                  {mostRecentObject
                  &&mostRecentObject.length>0&&
                  mostRecentObject.slice(0, 3).map((blog,key)=>{
                        return(
                           <div key={key} className="gallery_product col-lg-4 col-md-4 col-sm-6 col-xs-6 filter hdpe">
                              <div>   
                                 <img src={blog.node.featuredImage.node.sourceUrl} className="img-responsive"/>
                              </div>
                              <div className="post-details">
                              {blog.node.categories
                               &&blog.node.categories.nodes&&
                              <p className="tag">
                                 {blog.node.categories.nodes.map((cat, key)=>{
                                       return(<span key={key} className="ml-2">{cat.name}</span>)
                                 })
                                    
                                 }
                                 
                              </p>}
                                 <h5>{blog.node.title}</h5>
                                 <div dangerouslySetInnerHTML={{__html:blog.node.content}}></div>
                                 <div className="read-btn">
                                    <Link to={`/blog/${blog.node.slug}`}>Read More</Link>
                                 </div>
                              </div>
                            </div> 
                        )
                  })}
               </div>
            </div>
           )
        }}
   />)
}
 
export default MostReadArticle;