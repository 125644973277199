import React from 'react';
import singlecbg from "../../assets/images/single-c-bg.png"
import { StaticQuery, graphql, navigate } from "gatsby"

const Banner = () => (
   <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "blog"}}) {
                  edges {
                    node {
                      slug
                      TeamlanceBlog {
                        stickyPost {
                          buttonBgColour
                          buttonText
                          stickyPost {
                            ... on WpPost {
                              id
                              content
                              title
                              featuredImage {
                                node {
                                  sourceUrl
                                }
                              }
                              slug
                              date(formatString: "MMMM Do, YYYY")
                              uri
                              categories {
                                nodes {
                                  name
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
            }
         `}
         render={data => {
            return(
               data && 
               data.allWpPage &&
               data.allWpPage.edges &&
               data.allWpPage.edges[0] &&
               data.allWpPage.edges[0].node &&
               data.allWpPage.edges[0].node.TeamlanceBlog &&
               data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost &&
              <section className="b-banner">
               <div className="bn-bg blog-banner">
                  <img src={singlecbg} alt="case study" />
               </div>
               <div className="container">
                  <div className="row">
                     <div className="col-md-6  d-flex flex-column sectiontop-pd ">
                        <div className="banner-text case-heading my-auto ml-0 ">
                           <a className="site-links" href="#">
                              <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                 <circle cx="3" cy="3" r="3" fill="#19B8F8"/>
                              </svg>
                              {data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.categories.nodes[0].name}
                           </a>
                           <h4 className="blog-title">{data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.title}</h4>
                           <div className="banner-date">{data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.date}</div>
                           <div dangerouslySetInnerHTML={{__html:data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.content}} className="blog-disp"></div>
                           <div className="d-flex mb-3">
                              <button onClick={()=>{navigate(`/blog/${data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.slug}`)}}  className="more-btn ml-0">{data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.buttonText}</button> 
                           </div>
                        </div>
                     </div>
                     <div className="col-md-6 py-5">
                        <div className="d-flex py-5  my-5 justify-content-center align-items-center  sectiontop-pd sectionbtm-pd">
                           <img src={data.allWpPage.edges[0].node.TeamlanceBlog.stickyPost.stickyPost.featuredImage.node.sourceUrl} alt="case study" />
                        </div>
                     </div>
                  </div>
                </div>
              </section>
            )
         }}
      />
  
)

export default Banner;