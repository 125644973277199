import React, { useState } from 'react';
import { StaticQuery, graphql } from "gatsby"
import {Link} from "gatsby";

const Gallery = () => {
   const [activecat, setactiveCat] = useState(0);
   const [filterpost, setFilterPost] = useState([]);
   const [activecatslug, setActiveCatSlug] = useState("");
   const filterPost = (slug, data) => {
      if(slug){
         let filterdata = data.filter(function (filterdata) {
            return filterdata.node.slug === activecatslug
          })
         setFilterPost(filterdata);
      }else{
         setactiveCat(0);
         setFilterPost([]);
      }
   }
   return(
     <StaticQuery
      query={graphql`
         query {
            allWpPage(filter: {slug: {eq: "blog"}}) {
               edges {
                 node {
                   TeamlanceBlog {
                     allBlogPosts {
                       title
                       searchPlaceholder
                       searchIcon
                       allPosts {
                         ... on WpPost {
                           id
                           slug
                           content
                           title
                           featuredImage {
                             node {
                               sourceUrl
                             }
                           }
                           categories {
                              nodes {
                                name
                                slug
                              }
                            }
                         }
                       }
                     }
                   }
                 }
               }
             }
             allWpCategory {
               edges {
                 node {
                   name
                   slug
                   posts {
                     nodes {
                       author {
                         node {
                           avatar {
                             url
                           }
                           comments {
                             nodes {
                               author {
                                 node {
                                   email
                                   name
                                   url
                                 }
                               }
                               commentedOn {
                                 node {
                                   date(formatString: "MMMM Do, YYYY")
                                 }
                               }
                               content
                               date(formatString: "MMMM Do, YYYY")
                               type
                             }
                           }
                           name
                         }
                       }
                       title
                       status
                       slug
                       featuredImage {
                         node {
                           sourceUrl
                         }
                       }
                       categories {
                         nodes {
                           name
                           slug
                         }
                       }
                     }
                   }
                 }
               }
             }
         }
      `}
      render={data => {
         return(
            data && 
            data.allWpPage &&
            data.allWpPage.edges &&
            data.allWpPage.edges[0] &&
            data.allWpPage.edges[0].node &&
            data.allWpPage.edges[0].node.TeamlanceBlog &&
            data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts &&
            <section className="bl-posts">
              <div className="container">
               <div className="row d-flex justify-content-between mb-5 align-items-center">
                  <h1 className="posts-title">
                     {data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.title}
                  </h1>
                  <span>
                     <div className="cate-mobile desktop-hidden d-flex align-items-center">
                        <div className="form-group mb-0">
                           <select className="selectpicker form-control">
                              <option value="">Categories</option>
                              <option>All</option>
                              { data && 
                                data.allWpCategory &&
                                data.allWpCategory.edges
                                && data.allWpCategory.edges.map((cat, key)=>{
                                    return(
                                       <option value={cat.slug} key={key}>{cat.name}</option>
                                    )
                                }) 
                              }
                           </select>
                        </div>
                        <div className="ml-3">
                           <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M13.3559 13.3538C13.2621 13.4475 13.1349 13.5002 13.0023 13.5002C12.8697 13.5002 12.7425 13.4475 12.6487 13.3538L9.9473 10.6523C8.81106 11.6063 7.35043 12.0847 5.87001 11.988C4.38959 11.8912 3.00367 11.2267 2.00126 10.133C0.998846 9.03931 0.457334 7.60086 0.489649 6.11764C0.521963 4.63441 1.12561 3.22091 2.1747 2.17191C3.2238 1.12291 4.63735 0.519382 6.12058 0.487196C7.60381 0.45501 9.04221 0.996646 10.1358 1.99915C11.2294 3.00166 11.8938 4.38764 11.9905 5.86807C12.0871 7.3485 11.6085 8.80908 10.6544 9.94524L13.3559 12.6467C13.4496 12.7404 13.5023 12.8676 13.5023 13.0002C13.5023 13.1328 13.4496 13.26 13.3559 13.3538ZM6.25267 11.0002C7.19213 11.0002 8.11049 10.7216 8.89163 10.1996C9.67276 9.6777 10.2816 8.93585 10.6411 8.0679C11.0006 7.19995 11.0947 6.24488 10.9114 5.32347C10.7281 4.40206 10.2757 3.55569 9.61143 2.8914C8.94713 2.2271 8.10076 1.7747 7.17935 1.59142C6.25794 1.40814 5.30287 1.50221 4.43492 1.86172C3.56697 2.22124 2.82513 2.83006 2.30319 3.61119C1.78125 4.39233 1.50267 5.31069 1.50267 6.25015C1.50409 7.50949 2.005 8.71685 2.89549 9.60734C3.78598 10.4978 4.99333 10.9987 6.25267 11.0002Z" fill="black"/>
                           </svg>
                        </div>
                     </div>
                     {/*<div id="custom-search-input" className="mobile-hidden">
                        <div className="input-group col-md-12">
                           <input type="text" class="form-control input-lg" placeholder={data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.searchPlaceholder} />
                           <span className="input-group-btn">
                              <button dangerouslySetInnerHTML={{ __html: data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.searchIcon }} className="btn btn-info btn-lg" type="button">
                              </button>
                           </span>
                        </div>
                     </div>*/}
                  </span>
               </div>
               <div className="row">
                  <div className="col-12 mobile-hidden">
                     <div align="center">
                        <button onClick={()=>{filterPost("",data.allWpCategory.edges); setactiveCat(0)}} className={`${activecat===0?"btn btn-default filter-button active":"btn btn-default filter-button"}`} data-filter="all">All</button>
                        { data && 
                          data.allWpCategory &&
                          data.allWpCategory.edges
                          && data.allWpCategory.edges.map((cat, key)=>{
                              return(
                                 <button onClick={()=>{filterPost(cat.node.slug,data.allWpCategory.edges); setactiveCat(key+1); setActiveCatSlug(cat.node.slug)}} key={key} className={`btn btn-default filter-button ${activecat === key+1?"active":""}`} data-filter="hdpe">{cat.node.name}</button>
                              )
                          })
                        }
                        
                     </div>
                  </div>
                  <br/>
                  { !filterpost.length>0 && data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts &&
                    data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.allPosts &&
                    data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.allPosts.length>0&&
                    data.allWpPage.edges[0].node.TeamlanceBlog.allBlogPosts.allPosts.map((post,key)=>{
                     return(
                        <div key={key} className="gallery_product col-lg-4 col-md-4 col-sm-6 col-xs-6 filter hdpe">
                           <div>   
                              <img src={post.featuredImage.node.sourceUrl} className="img-responsive"/> 
                           </div>
                           <div className="post-details">
                            {post.categories
                               &&post.categories.nodes&&
                              <p className="tag">
                                 {post.categories.nodes.map((cat, key)=>{
                                       return(<span key={key} className="ml-2">{cat.name}</span>)
                                 })
                                    
                                 }
                                 
                              </p>}
                              <h5>{post.title}</h5>
                              <span dangerouslySetInnerHTML={{ __html: post.content }}></span>
                              <div className="read-btn">
                                 <Link to={`/blog/${post.slug}`}>Read More</Link>
                              </div>
                           </div>
                         </div>
                     )
                  })}
                  {filterpost.length>0
                   &&filterpost[0].node.posts
                   &&filterpost[0].node.posts.nodes
                   &&filterpost[0].node.posts.nodes.length>0
                   &&filterpost[0].node.posts.nodes.map((post,key)=>{
                       return(
                          <div key={key} className="gallery_product col-lg-4 col-md-4 col-sm-6 col-xs-6 filter hdpe">
                           <div>   
                              <img src={post.featuredImage.node.sourceUrl} className="img-responsive"/> 
                           </div>
                           <div className="post-details">
                              {post.categories
                               &&post.categories.nodes&&
                              <p className="tag">
                                 {post.categories.nodes.map((cat, key)=>{
                                       return(<span key={key} className="ml-2">{cat.name}</span>)
                                 })
                                    
                                 }
                                 
                              </p>}
                              <h5>{post.title}</h5>
                              <span dangerouslySetInnerHTML={{ __html: post.content }}></span>
                              <div className="read-btn">
                                 <Link to={`/blog/${post.slug}`}>Read More</Link>
                              </div>
                           </div>
                          </div>
                       )
                  })}
               </div>
            </div>
            <div className="svg-graphics">
               <svg width="540" height="227" viewBox="0 0 540 227" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M77.7965 9.85423L-47.5 146.5V226.5L540 130.5L108.508 1.38836C97.4943 -1.90716 85.5659 1.38098 77.7965 9.85423Z" fill="#F4F8FF"/>
               </svg>
            </div>
          </section>
         )
      }}
    />
   )
}

export default Gallery;