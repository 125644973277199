import React from 'react';
import Layout from '../components/layout';
import SEO from "../components/seo";
import Banner from "../components/blog/banner";
import Gallery from "../components/blog/gallery";
import Newsletter from "../components/blog/newsletter";
import MostReadArticle from "../components/blog/mostreadarticle";

const Blog = (props) => (
    <Layout {...props}>
          <SEO title="Blog"/>
          <div class="col-12 wrapper main-bggrey p-0">
              <div class="col-12 inner-page-content home-inner px-0">
                  <Banner/>
              </div>
          </div>
          <Gallery/>
          <Newsletter/>
          <MostReadArticle/>
    </Layout>
)

export default Blog;