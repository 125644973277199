import React from 'react';
import newsbg from "../../assets/images/news-bg.png";
import { StaticQuery, graphql } from "gatsby"


const NewsLetter = () => (
    <StaticQuery
         query={graphql`
            query {
               allWpPage(filter: {slug: {eq: "blog"}}) {
                  edges {
                    node {
                        TeamlanceBlog {
                            subscribeToOurNewsletter {
                              buttonText
                              buttonBgColour
                              description
                              mailTo
                              placeholder
                              title
                            }
                          }
                    }
                  }
                }
            }
         `}
         render={data => {
             return(
             
            data && 
            data.allWpPage &&
            data.allWpPage.edges &&
            data.allWpPage.edges[0] &&
            data.allWpPage.edges[0].node &&
            data.allWpPage.edges[0].node.TeamlanceBlog &&
            data.allWpPage.edges[0].node.TeamlanceBlog.subscribeToOurNewsletter &&
            <section className="news-bg mobile-n-bg">
                <div className="news-bginner">
                    <img src={newsbg}/> 
                </div>
                <div className="container text-center">
                    <h2 className="text-light news-title mb-0">{data.allWpPage.edges[0].node.TeamlanceBlog.subscribeToOurNewsletter.title}</h2>
                    <p className=" news-disp">{data.allWpPage.edges[0].node.TeamlanceBlog.subscribeToOurNewsletter.description}</p>
                    <div className="input-group news-input">
                        <input type="email" class="form-control" placeholder={data.allWpPage.edges[0].node.TeamlanceBlog.subscribeToOurNewsletter.placeholder}/>
                        <span className="input-group-btn">
                            <button className="btn" type="submit">{data.allWpPage.edges[0].node.TeamlanceBlog.subscribeToOurNewsletter.buttonText}</button>
                        </span>
                    </div>
                </div>
             </section>
             )
         }}
      />
    
)

export default NewsLetter;